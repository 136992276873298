import { ReactNode } from 'react';
import { ImageBackground, View } from 'react-native';
import { Edge, useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import Button, { Props as ButtonProps } from './Button';
import useModalNavContext from './hooks/useModalNavContext';
import useUIContext from './hooks/useUIContext';
import KeyboardAvoidingViewWrapper from './KeyboardAvoidingViewWrapper';
import RootView from './RootView';
import ScrollContainer from './ScrollContainer';
import { useFlowConfig } from './utils/flow';

export type ModalNavControlsProps = {
	title: string;
	onPress: () => void;
	disabled?: boolean;
	loading?: boolean;
} & ButtonProps;

type ModalNavViewProps = {
	children: ReactNode;
	footer?: ReactNode;
	controls?: ReactNode;
	noBottomGutter?: boolean;
	noTopGutter?: boolean;
	flexContentContainer?: boolean;
	hasGlowingControls?: boolean;
	shouldEnableKeyboardAvoidingView?: boolean;
};

const Wrapper = ({
	shouldEnableKeyboardAvoidingView,
	children,
}: {
	shouldEnableKeyboardAvoidingView: boolean;
	children: ReactNode;
}) => {
	if (!shouldEnableKeyboardAvoidingView) {
		return <>{children}</>;
	}
	return <KeyboardAvoidingViewWrapper>{children}</KeyboardAvoidingViewWrapper>;
};

const ModalNavView = ({
	children,
	footer,
	controls,
	noBottomGutter,
	noTopGutter,
	flexContentContainer,
	hasGlowingControls,
	shouldEnableKeyboardAvoidingView = false,
}: ModalNavViewProps) => {
	const { hasTransparentHeader } = useFlowConfig();
	const { pinControls } = useModalNavContext();
	const { isWeb, isIos, isDesktop } = useUIContext();
	const { styles, theme } = useStyles(stylesheet, { flexContentContainer });

	const shouldPinControls = pinControls ?? !isWeb;

	const safeAreaInsets = useSafeAreaInsets();
	const contentPaddingTop = hasTransparentHeader
		? (safeAreaInsets.top || theme.spacing.xl4) + theme.spacing.xl3
		: theme.spacing.none;

	const SafeAreaContainer = shouldPinControls
		? RootView.WithSafeArea
		: RootView.WithSafeScrollArea;

	const content = (
		<View style={[styles.content, { paddingTop: contentPaddingTop }]}>
			{children}
		</View>
	);

	const edges: Edge[] = (() => {
		if ((isIos && isDesktop) || shouldPinControls) return ['left', 'right'];
		return ['left', 'right', 'bottom'];
	})();

	const paddingProps = {
		noTopGutter: hasTransparentHeader || noTopGutter,
		noBottomGutter: shouldPinControls || noBottomGutter,
	};

	return (
		<SafeAreaContainer edges={edges} innerStyle={styles.inner}>
			<RootView.Page
				{...paddingProps}
				style={styles.page}
				innerStyle={styles.pageInner}
			>
				{!shouldPinControls ? (
					content
				) : (
					<View style={styles.scrollContainerWrap}>
						<RootView.EscapePadding {...paddingProps}>
							<Wrapper
								shouldEnableKeyboardAvoidingView={
									shouldEnableKeyboardAvoidingView
								}
							>
								<ScrollContainer style={styles.scrollContainer}>
									<RootView.Padding {...paddingProps}>
										{content}
										{footer && (
											<View style={styles.footerContainer}>{footer}</View>
										)}
									</RootView.Padding>
								</ScrollContainer>
							</Wrapper>
						</RootView.EscapePadding>
					</View>
				)}
				{shouldPinControls && controls ? (
					<ModalNavPinnedControlsContainer
						hasGlowingControls={hasGlowingControls}
						controls={controls}
					/>
				) : (
					controls
				)}
				{footer && !shouldPinControls && (
					<View style={styles.footerContainer}>{footer}</View>
				)}
			</RootView.Page>
		</SafeAreaContainer>
	);
};

const ModalNavControls = ({
	previous,
	next,
	direction = 'row',
}: {
	previous?: ModalNavControlsProps;
	next?: ModalNavControlsProps;
	direction?: 'row' | 'column';
}) => {
	const isRow = direction === 'row';
	const { isWeb } = useUIContext();
	const previousButtonSize =
		isRow && next ? 'inline_center' : !isRow && next ? 'fixed' : 'full';
	const nextButtonIsFull = isWeb || (isRow && previous);
	const { styles } = useStyles(stylesheet, { direction });

	return (
		<View style={styles.controls}>
			{previous && (
				<Button size={previousButtonSize} type="secondary" {...previous} />
			)}
			{next && (
				<View style={styles.controlNext}>
					<Button size={nextButtonIsFull ? 'full' : 'fixed'} {...next} />
				</View>
			)}
		</View>
	);
};

const ModalNavPinnedControlsContainer = ({
	controls,
	hasGlowingControls = false,
}: {
	controls: ReactNode;
	hasGlowingControls?: boolean;
}) => {
	const { styles, theme } = useStyles(stylesheet, { hasGlowingControls });
	const source = hasGlowingControls
		? require('./assets/glowing-backgrounds/ito_onboarding_controls_gradient.png')
		: require('./assets/patterns/pattern_8.png');
	const { bottom } = useSafeAreaInsets();

	return (
		<View
			style={[
				styles.pinnedControls,
				{ paddingBottom: Math.max(bottom, theme.spacing.xl) },
			]}
		>
			<ImageBackground
				source={source}
				resizeMode="cover"
				style={styles.bottomGradient}
			/>
			<View style={styles.pinnedControlsContent}>{controls}</View>
		</View>
	);
};

const stylesheet = createStyleSheet(({ spacing, colors }) => ({
	scrollContainerWrap: { flex: 1 },
	scrollContainer: { flex: 1 },
	inner: { flex: 1 },
	content: {
		paddingBottom: spacing.xl3,
		variants: {
			flexContentContainer: {
				true: {
					flex: 1,
				},
			},
		},
	},
	page: { flexShrink: 1 },
	pageInner: { flex: undefined },
	controls: {
		variants: {
			direction: {
				row: {
					gap: spacing.xl,
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'center',
				},
				column: {
					gap: spacing.md,
					flexDirection: 'column',
					alignItems: 'center',
				},
			},
		},
	},
	pinnedControls: {
		variants: {
			hasGlowingControls: {
				false: {
					borderTopWidth: 1,
					borderTopColor: 'rgba(255, 255, 255, 0.05)',
					shadowColor: colors.content_on_fill,
					shadowOffset: {
						width: 0,
						height: -4,
					},
					shadowOpacity: 0.2,
					shadowRadius: 30,
				},
			},
		},
		marginHorizontal: -spacing.xl,
	},
	pinnedControlsContent: {
		paddingTop: spacing.xl,
		paddingHorizontal: spacing.xl,
	},
	controlNext: {
		alignItems: 'center',
		variants: {
			direction: {
				column: {
					width: '100%',
				},
				row: {
					flex: 1,
				},
			},
		},
	},
	controlsColumn: {
		flexDirection: 'column',
		gap: spacing.xs,
	},
	controlsFlexEnd: {
		justifyContent: 'flex-end',
	},
	footerContainer: {
		marginTop: spacing.xl3,
	},
	bottomGradient: {
		variants: {
			hasGlowingControls: {
				true: {
					position: 'absolute',
					bottom: 0,
					left: 0,
					right: 0,
					height: 190,
				},
			},
		},
	},
}));

export default {
	View: ModalNavView,
	Controls: ModalNavControls,
	PinnedControls: ModalNavPinnedControlsContainer,
};
