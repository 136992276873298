import { BrandColor } from '@wearemojo/ui-constants';
import { LinearGradient, LinearGradientProps } from 'expo-linear-gradient';
import { ComponentType, useEffect } from 'react';
import {
	Pressable,
	StyleProp,
	StyleSheet,
	TextStyle,
	View,
} from 'react-native';
import Animated, {
	ReduceMotion,
	useAnimatedStyle,
	useSharedValue,
	withTiming,
} from 'react-native-reanimated';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import Text from './Text';
import { LinkProviderType } from './utils/links';
import { MojoIcon, PressableCallback } from './utils/types';

type OptionProps = {
	title: string;
	rightIcon?: ComponentType<MojoIcon>;
	percentage?: number;
	selected?: boolean;
	barPercentage?: number;
	onPress?: () => void;
	showResult?: boolean;
	linkProvider?: LinkProviderType;
	textStyle?: StyleProp<TextStyle>;
	disabled?: boolean;
};

const OptionsButton = ({
	title,
	rightIcon: RightIcon,
	percentage = 0,
	selected = false,
	barPercentage = 1,
	onPress,
	showResult = false,
	textStyle,
	linkProvider: LinkProvider,
	disabled,
}: OptionProps) => {
	const {
		styles,
		theme: { iconSize, colors },
	} = useStyles(stylesheet);

	const formattedPercentage = showResult
		? `${Math.round(percentage * 100)}%`
		: undefined;

	const isDisabled = !onPress || disabled;

	const widthAnim = useSharedValue(0);
	const animatedStyle = useAnimatedStyle(() => ({
		width: `${widthAnim.value * 100}%`,
	}));

	useEffect(() => {
		widthAnim.value = withTiming(barPercentage, {
			duration: 300,
			reduceMotion: ReduceMotion.Never,
		});
	}, [widthAnim, barPercentage]);

	const button = (
		<Pressable style={styles.pressable} onPress={onPress} disabled={isDisabled}>
			{(state) => {
				const { hovered } = state as PressableCallback;

				const wrapperGradientColors: LinearGradientProps['colors'] =
					(hovered || selected) && !disabled
						? [BrandColor.primary_yellow, BrandColor.primary_blue]
						: ['transparent', 'transparent'];

				return (
					<LinearGradient
						colors={wrapperGradientColors}
						start={GRADIENT_START}
						end={GRADIENT_END}
						style={styles.root}
					>
						<View style={styles.optionContainer}>
							{selected && !showResult && (
								<LinearGradient
									colors={SELECTED_GRADIENT_COLORS}
									start={GRADIENT_START}
									end={GRADIENT_END}
									style={StyleSheet.absoluteFillObject}
								/>
							)}
							<View style={StyleSheet.absoluteFillObject}>
								<Animated.View
									style={[styles.optionBar, showResult && animatedStyle]}
								>
									{showResult && (
										<LinearGradient
											colors={SELECTED_GRADIENT_COLORS}
											style={styles.optionBarGradient}
											start={GRADIENT_START}
											end={GRADIENT_END}
										/>
									)}
								</Animated.View>
							</View>
							<View style={styles.optionContent}>
								<View style={styles.iconTextContainer}>
									<Text
										variant="body_md"
										style={[styles.titleStyle, textStyle]}
									>
										{title}
									</Text>
									{RightIcon && (
										<RightIcon
											size={iconSize.sm}
											color={colors.content_primary}
										/>
									)}
								</View>
								{!!formattedPercentage && (
									<Text variant="body_md">{formattedPercentage}</Text>
								)}
							</View>
						</View>
					</LinearGradient>
				);
			}}
		</Pressable>
	);

	if (LinkProvider) return <LinkProvider>{button}</LinkProvider>;

	return button;
};

const SELECTED_GRADIENT_COLORS: LinearGradientProps['colors'] = [
	`${BrandColor.primary_yellow}66`,
	`${BrandColor.primary_blue}66`,
];

const GRADIENT_START = { x: 0, y: 0.5 };
const GRADIENT_END = { x: 1, y: 0.53 };

const stylesheet = createStyleSheet(({ radius, colors, spacing }) => ({
	pressable: {
		borderRadius: radius.full,
		backgroundColor: colors.background_primary,
	},
	root: {
		padding: 2,
		borderRadius: radius.full,
		marginTop: -2, // Hack to maintain space between options
	},
	optionContainer: {
		position: 'relative',
		borderRadius: radius.full,
		backgroundColor: colors.poll_background,
		overflow: 'hidden',
	},
	optionBar: {
		height: '100%',
	},
	optionBarGradient: {
		width: '100%',
		height: '100%',
		borderRadius: radius.full,
	},
	optionContent: {
		paddingHorizontal: spacing.lg,
		paddingVertical: spacing.md,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		gap: spacing.xs,
	},
	iconTextContainer: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		gap: spacing.xs,
	},
	titleStyle: {
		flex: 1,
		width: 1,
	},
}));

export default OptionsButton;
